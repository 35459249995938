const cruiseData = {
  companies: [
    {
      name: "AIDA Cruises",
      logo: "/ShipCompany/Aida-Cruise/aida-cruises-logo.3nz4mmum.webp",
      modernizationYear: 2018,
      ships: [
        {
          name: "AIDAbella",
          review: { score: 7.6, text: "Good" },
          passengers: 2050,
          cruises: 144,
          crew: 1000,
          image: "/ShipCompany/Aida-Cruise/ship-1.jpg",
        },
        {
          name: "AIDAblu",
          review: { score: 7.9, text: "Good" },
          passengers: 2198,
          crew: 1000,
          cruises: 144,
          image: "/ShipCompany/Aida-Cruise/ship-2.webp",
        },
        {
          name: "AIDAcosma",
          review: { score: "", text: "Very Good" },
          passengers: 5200,
          crew: 1000,
          cruises: 336,
          image: "/ShipCompany/Aida-Cruise/ship-3.webp",
        },
        {
          name: "AIDadiva",
          review: { score: 7.8, text: "Very Good" },
          passengers: 2050,
          crew: 1000,
          cruises: 123,
          image: "/ShipCompany/Aida-Cruise/ship-4.webp",
        },
        {
          name: "AIDAluna",
          review: { score: 7.5, text: "Very Good" },
          passengers: 2050,
          crew: 1000,
          cruises: 128,
          image: "/ShipCompany/Aida-Cruise/ship-5.webp",
        },
        {
          name: "AIDAmar",
          review: { score: 7.9, text: "Very Good" },
          passengers: 2194,
          crew: 1000,
          cruises: 157,
          image: "/ShipCompany/Aida-Cruise/ship-6.webp",
        },
        {
          name: "AIDAnova",
          review: { score: 8.1, text: "Very Good" },
          passengers: 5200,
          crew: 1000,
          cruises: 254,
          image: "/ShipCompany/Aida-Cruise/ship-7.webp",
        },
        {
          name: "AIDAperla",
          review: { score: 8.0, text: "Very Good" },
          passengers: 3300,
          crew: 1000,
          cruises: 189,
          image: "/ShipCompany/Aida-Cruise/ship-8.webp",
        },
        {
          name: "AIDAprima",
          review: { score: "", text: "Very Good" },
          passengers: 3300,
          crew: 1000,
          cruises: 243,
          image: "/ShipCompany/Aida-Cruise/ship-9.webp",
        },
        {
          name: "AIDAsol",
          review: { score: 7.9, text: "Good" },
          passengers: 2194,
          crew: 1000,
          cruises: 73,
          image: "/ShipCompany/Aida-Cruise/ship-10.webp",
        },
        {
          name: "AIDAstella",
          review: { score: "", text: "Very Good" },
          passengers: 2820,
          crew: 1000,
          cruises: 79,
          image: "/ShipCompany/Aida-Cruise/ship-11.webp",
        },
      ],
    },
    {
      name: "Royal Caribbean",
      logo: "/ShipCompany/Royal-Cruise/royal-caribbean-logo.8ci6zrf7.webp",
      modernizationYear: 2018,
      ships: [
        {
          name: "Adventure of the Seas",
          review: { score: "", text: "Excellent" },
          passengers: 3114,
          crew: 1000,
          cruises: 125,
          image: "/ShipCompany/Royal-Cruise/ship-1.webp",
        },
        {
          name: "Allure of the Seas",
          review: { score: 8.4, text: "Very Good" },
          passengers: 5400,
          crew: 1000,
          cruises: 130,
          image: "/ShipCompany/Royal-Cruise/ship-2.webp",
        },
        {
          name: "Anthem of the Seas",
          review: { score: 8.6, text: "Excellent" },
          passengers: 4180,
          crew: 1000,
          cruises: 97,
          image: "/ShipCompany/Royal-Cruise/ship-3.webp",
        },
        {
          name: "Briliance of the Seas",
          review: { score: 7.5, text: "Good" },
          passengers: 2252,
          crew: 1000,
          cruises: 171,
          image: "/ShipCompany/Royal-Cruise/ship-4.webp",
        },
        {
          name: "Enchantment of the Seas",
          review: { score: 8.2, text: "Very Good" },
          passengers: 2254,
          crew: 1000,
          cruises: 171,
          image: "/ShipCompany/Royal-Cruise/ship-5.webp",
        },
        {
          name: "Explorer of the Seas",
          review: { score: 7.4, text: "Very Good" },
          passengers: 3114,
          crew: 1000,
          cruises: 96,
          image: "/ShipCompany/Royal-Cruise/ship-6.webp",
        },
        {
          name: "Freedom of the Seas",
          review: { score: 7.8, text: "Very Good" },
          passengers: 3634,
          crew: 1000,
          cruises: 107,
          image: "/ShipCompany/Royal-Cruise/ship-7.webp",
        },
        {
          name: "Grandeur of the Seas",
          review: { score: 8.8, text: "Very Good" },
          passengers: 1992,
          crew: 1000,
          cruises: 73,
          image: "/ShipCompany/Royal-Cruise/ship-8.webp",
        },
        {
          name: "Harmony of the Seas",
          review: { score: "", text: "Very Good" },
          passengers: 5479,
          crew: 1000,
          cruises: 75,
          image: "/ShipCompany/Royal-Cruise/ship-9.webp",
        },
        {
          name: "Icon of the Seas",
          review: { score: "", text: "Very Good" },
          passengers: 5610,
          crew: 1000,
          cruises: 126,
          image: "/ShipCompany/Royal-Cruise/ship-10.webp",
        },
        {
          name: "Independence of the Seas",
          review: { score: 7.9, text: "Very Good" },
          passengers: 3634,
          crew: 1000,
          cruises: 88,
          image: "/ShipCompany/Royal-Cruise/ship-11.webp",
        },
        {
          name: "Jewel of the Seas",
          review: { score: "", text: "Very Good" },
          passengers: 2112,
          crew: 1000,
          cruises: 93,
          image: "/ShipCompany/Royal-Cruise/ship-12.webp",
        },
        {
          name: "Liberty of the Seas",
          review: { score: "", text: "Very Good" },
          passengers: 3634,
          crew: 1000,
          cruises: 141,
          image: "/ShipCompany/Royal-Cruise/ship-13.webp",
        },
        {
          name: "Mariner of the Seas",
          review: { score: "", text: "Very Good" },
          passengers: 3114,
          crew: 1000,
          cruises: 92,
          image: "/ShipCompany/Royal-Cruise/ship-14.webp",
        },
        {
          name: "Navigator of the Seas",
          review: { score: 8.8, text: "Very Good" },
          passengers: 3276,
          crew: 1000,
          cruises: 112,
          image: "/ShipCompany/Royal-Cruise/ship-15.webp",
        },
        {
          name: "Oasis of the Seas",
          review: { score: 8.6, text: "Very Good" },
          passengers: 5400,
          crew: 1000,
          cruises: 102,
          image: "/ShipCompany/Royal-Cruise/ship-16.webp",
        },
        {
          name: "Odyssey of the Seas",
          review: { score: 8.6, text: "Very Good" },
          passengers: 4180,
          crew: 1000,
          cruises: 111,
          image: "/ShipCompany/Royal-Cruise/ship-17.webp",
        },
        {
          name: "Ovation of the Seas",
          review: { score: 8.0, text: "Very Good" },
          passengers: 4180,
          crew: 1000,
          cruises: 88,
          image: "/ShipCompany/Royal-Cruise/ship-18.webp",
        },
        {
          name: "Quantum of the Seas",
          review: { score: 8.0, text: "Good" },
          passengers: 4180,
          crew: 1000,
          cruises: 88,
          image: "/ShipCompany/Royal-Cruise/ship-19.webp",
        },
        {
          name: "Radiance of the Seas",
          review: { score: 7.8, text: "Good" },
          passengers: 2546,
          crew: 1000,
          cruises: 142,
          image: "/ShipCompany/Royal-Cruise/ship-20.webp",
        },
        {
          name: "Vision of the Seas",
          review: { score: 8.0, text: "Very Good" },
          passengers: 2000,
          crew: 1000,
          cruises: 60,
          image: "/ShipCompany/Royal-Cruise/ship-21.webp",
        },
        {
          name: "Utopia of the Seas",
          review: { score: "", text: "Very Good" },
          passengers: 5508,
          crew: 1000,
          cruises: 147,
          image: "/ShipCompany/Royal-Cruise/ship-22.webp",
        },
      ],
    },
    {
      name: "Silversea Cruises",
      logo: "/ShipCompany/Silver-Cruise/silversea-cruises-logo.55rfxx75.webp",
      modernizationYear: 2017,
      ships: [
        {
          name: "Silver Cloud",
          review: { score: "", text: "Good" },
          passengers: 240,
          crew: 1000,
          cruises: 40,
          image: "/ShipCompany/Silver-Cruise/ship-1.webp",
        },
        {
          name: "Silver Dawn",
          review: { score: "", text: "Good" },
          passengers: 596,
          crew: 1000,
          cruises: 78,
          image: "/ShipCompany/Silver-Cruise/ship-2.webp",
        },
        {
          name: "Silver Endeavor",
          review: { score: "", text: "Good" },
          passengers: 220,
          crew: 1000,
          cruises: 38,
          image: "/ShipCompany/Silver-Cruise/ship-3.webp",
        },
        {
          name: "Silver Moon",
          review: { score: "", text: "Good" },
          passengers: 596,
          crew: 1000,
          cruises: 69,
          image: "/ShipCompany/Silver-Cruise/ship-4.webp",
        },
        {
          name: "Silver Muse",
          review: { score: "", text: "Very Good" },
          passengers: 728,
          crew: 1000,
          cruises: 72,
          image: "/ShipCompany/Silver-Cruise/ship-5.webp",
        },
        {
          name: "Silver Nova",
          review: { score: "", text: "Very Good" },
          passengers: 100,
          crew: 1000,
          cruises: 76,
          image: "/ShipCompany/Silver-Cruise/ship-6.webp",
        },
        {
          name: "Silver Origin",
          review: { score: "", text: "Very Good" },
          passengers: 728,
          crew: 1000,
          cruises: 66,
          image: "/ShipCompany/Silver-Cruise/ship-7.webp",
        },
        {
          name: "Silver Ray",
          review: { score: "", text: "Very Good" },
          passengers: 392,
          crew: 1000,
          cruises: 80,
          image: "/ShipCompany/Silver-Cruise/ship-8.webp",
        },
        {
          name: "Silver Shadow",
          review: { score: "", text: "Good" },
          passengers: 608,
          crew: 1000,
          cruises: 65,
          image: "/ShipCompany/Silver-Cruise/ship-9.webp",
        },
        {
          name: "Silver Spirit",
          review: { score: "", text: "Good" },
          passengers: 392,
          crew: 1000,
          cruises: 67,
          image: "/ShipCompany/Silver-Cruise/ship-10.webp",
        },
        {
          name: "Silver Whisper",
          review: { score: "", text: "Good" },
          passengers: 274,
          crew: 1000,
          cruises: 60,
          image: "/ShipCompany/Silver-Cruise/ship-11.webp",
        },
        {
          name: "Silver Wind",
          review: { score: "", text: "Good" },
          passengers: 274,
          crew: 1000,
          cruises: 35,
          image: "/ShipCompany/Silver-Cruise/ship-12.webp",
        },
      ],
    },
    {
      name: "Phoenix Cruises",
      logo: "/ShipCompany/Phoenix-Cruise/phoenix-reisen-logo.2irotunr.webp",
      modernizationYear: 2019,
      ships: [
        {
          name: "M/S A-Silver",
          review: { score: 8.5, text: "Very Good" },
          passengers: 180,
          crew: 1000,
          cruises: 6,
          image: "/ShipCompany/Phoenix-Cruise/ship-1.webp",
        },
        {
          name: "M/S Adora",
          review: { score: 8.8, text: "Excellent" },
          passengers: 190,
          crew: 1000,
          cruises: 48,
          image: "/ShipCompany/Phoenix-Cruise/ship-2.webp",
        },
        {
          name: "M/S Alena",
          review: { score: 8.4, text: "Very Good" },
          passengers: 190,
          crew: 1000,
          cruises: 49,
          image: "/ShipCompany/Phoenix-Cruise/ship-3.webp",
        },
        {
          name: "M/S Alina",
          review: { score: 8.0, text: "Very Good" },
          passengers: 220,
          crew: 1000,
          cruises: 53,
          image: "/ShipCompany/Phoenix-Cruise/ship-4.webp",
        },
        {
          name: "M/S Alisa",
          review: { score: "", text: "Very Good" },
          passengers: 190,
          crew: 1000,
          cruises: 59,
          image: "/ShipCompany/Phoenix-Cruise/ship-5.webp",
        },
        {
          name: "M/S Amadea",
          review: { score: 8.4, text: "Very Good" },
          passengers: 600,
          crew: 1000,
          cruises: 44,
          image: "/ShipCompany/Phoenix-Cruise/ship-6.webp",
        },
        {
          name: "M/S Amazon Clipper Premium",
          review: { score: "", text: "Good" },
          passengers: 32,
          crew: 1000,
          cruises: 5,
          image: "/ShipCompany/Phoenix-Cruise/ship-7.webp",
        },
        {
          name: "M/S Amelia",
          review: { score: 8.3, text: "Very Good" },
          passengers: 216,
          crew: 1000,
          cruises: 55,
          image: "/ShipCompany/Phoenix-Cruise/ship-8.webp",
        },
        {
          name: "M/S Amera",
          review: { score: 8.2, text: "Exceptional" },
          passengers: 835,
          crew: 1000,
          cruises: 49,
          image: "/ShipCompany/Phoenix-Cruise/ship-9.webp",
        },
        {
          name: "M/S Amina",
          review: { score: 8.7, text: "Excellent" },
          passengers: 208,
          crew: 1000,
          cruises: 57,
          image: "/ShipCompany/Phoenix-Cruise/ship-10.webp",
        },
        {
          name: "M/S Andrea",
          review: { score: 9.0, text: "Very Good" },
          passengers: 190,
          crew: 1000,
          cruises: 48,
          image: "/ShipCompany/Phoenix-Cruise/ship-11.webp",
        },
        {
          name: "M/S Annabelle",
          review: { score: 8.1, text: "Very Good" },
          passengers: 180,
          crew: 1000,
          cruises: 26,
          image: "/ShipCompany/Phoenix-Cruise/ship-12.webp",
        },
        {
          name: "M/S Annika",
          review: { score: 8.8, text: "Excellent" },
          passengers: 180,
          crew: 1000,
          cruises: 56,
          image: "/ShipCompany/Phoenix-Cruise/ship-13.webp",
        },
        {
          name: "M/S Artanca",
          review: { score: 8.6, text: "Very Good" },
          passengers: 1200,
          crew: 1000,
          cruises: 54,
          image: "/ShipCompany/Phoenix-Cruise/ship-14.webp",
        },
        {
          name: "M/S Asara",
          review: { score: 8.6, text: "Good" },
          passengers: 190,
          crew: 1000,
          cruises: 49,
          image: "/ShipCompany/Phoenix-Cruise/ship-15.webp",
        },
        {
          name: "M/S Aurele",
          review: { score: "", text: "Very Good" },
          passengers: 208,
          crew: 1000,
          cruises: 58,
          image: "/ShipCompany/Phoenix-Cruise/ship-16.webp",
        },
        {
          name: "M/S Gloria",
          review: { score: "", text: "Very Good" },
          passengers: 150,
          crew: 1000,
          cruises: 6,
          image: "/ShipCompany/Phoenix-Cruise/ship-17.webp",
        },
        {
          name: "Cyrano de Bergerac",
          review: { score: "", text: "Good" },
          passengers: 174,
          crew: 1000,
          cruises: "no cruises yet",
          image: "/ShipCompany/Phoenix-Cruise/ship-18.webp",
        },
        {
          name: "M/S Swiss Crystal",
          review: { score: 7.6, text: "Good" },
          passengers: 121,
          crew: 1000,
          cruises: 31,
          image: "/ShipCompany/Phoenix-Cruise/ship-19.webp",
        },
      ],
    },
    {
      name: "Carnival Cruise Line",
      logo: "/ShipCompany/Carnival-Cruise/carnival-cruise-logo-line.3rnxu4kw.webp",
      modernizationYear: 2019,
      ships: [
        {
          name: "Carnival Breeze",
          review: { score: 8.1, text: "" },
          passengers: 3690,
          crew: 1000,
          cruises: 181,
          image: "/ShipCompany/Carnival-Cruise/ship-1.webp",
        },
        {
          name: "Carnival Celebration",
          review: { score: 8.1, text: "" },
          passengers: 5182,
          crew: 1000,
          cruises: 167,
          image: "/ShipCompany/Carnival-Cruise/ship-2.webp",
        },
        {
          name: "Carnival Conquest",
          review: { score: 8.1, text: "" },
          passengers: 2980,
          crew: 1000,
          cruises: 137,
          image: "/ShipCompany/Carnival-Cruise/ship-3.webp",
        },
        {
          name: "Carnival Dream",
          review: { score: 8.1, text: "" },
          passengers: 3646,
          crew: 1000,
          cruises: 115,
          image: "/ShipCompany/Carnival-Cruise/ship-4.webp",
        },
        {
          name: "Carnival Elafion",
          review: { score: 8.1, text: "" },
          passengers: 2188,
          crew: 1000,
          cruises: "",
          image: "/ShipCompany/Carnival-Cruise/ship-5.webp",
        },
        {
          name: "Carnival Firenze",
          review: { score: 8.6, text: "" },
          passengers: 4126,
          crew: 1000,
          cruises: "no cruises yet",
          image: "/ShipCompany/Carnival-Cruise/ship-6.webp",
        },
        {
          name: "Carnival Freedom",
          review: { score: 8.6, text: "" },
          passengers: 2980,
          crew: 1000,
          cruises: 113,
          image: "/ShipCompany/Carnival-Cruise/ship-7.webp",
        },
        {
          name: "Carnival Glory",
          review: { score: 8.6, text: "" },
          passengers: 2980,
          crew: 1000,
          cruises: 184,
          image: "/ShipCompany/Carnival-Cruise/ship-8.webp",
        },
        {
          name: "Carnival Horizon",
          review: { score: 8.6, text: "" },
          passengers: 3954,
          crew: 1000,
          cruises: 140,
          image: "/ShipCompany/Carnival-Cruise/ship-9.webp",
        },
        {
          name: "Carnival Jubilee",
          review: { score: "", text: "" },
          passengers: 5374,
          crew: 1000,
          cruises: 75,
          image: "/ShipCompany/Carnival-Cruise/ship-10.webp",
        },
        {
          name: "Carnival Legend",
          review: { score: "", text: "" },
          passengers: 2124,
          crew: 1000,
          cruises: 75,
          image: "/ShipCompany/Carnival-Cruise/ship-11.webp",
        },
        {
          name: "Carnival Miracle",
          review: { score: "", text: "" },
          passengers: 743,
          crew: 1000,
          cruises: 88,
          image: "/ShipCompany/Carnival-Cruise/ship-12.webp",
        },
        {
          name: "Carnival Pride",
          review: { score: "", text: "" },
          passengers: 1274,
          crew: 1000,
          cruises: 80,
          image: "/ShipCompany/Carnival-Cruise/ship-13.webp",
        },
        {
          name: "Carnival Paradice",
          review: { score: "", text: "" },
          passengers: 3300,
          crew: 1000,
          cruises: 55,
          image: "/ShipCompany/Carnival-Cruise/ship-14.webp",
        },
      ],
    },
    {
      name: "Oceania Cruises",
      logo: "/ShipCompany/Oceania-Cruise/oceania-cruises-logo.bvjks7pn.webp",
      modernizationYear: 2019,
      ships: [
        {
          name: "Allura",
          review: { score: "", text: "Very Good" },
          passengers: 1200,
          crew: 1000,
          cruises: 57,
          image: "/ShipCompany/Oceania-Cruise/ship-1.webp",
        },
        {
          name: "Insigma",
          review: { score: "", text: "Very Good" },
          passengers: 684,
          crew: 1000,
          cruises: 79,
          image: "/ShipCompany/Oceania-Cruise/ship-2.webp",
        },
        {
          name: "Marina",
          review: { score: 7.7, text: "Very Good" },
          passengers: 1250,
          crew: 1000,
          cruises: 85,
          image: "/ShipCompany/Oceania-Cruise/ship-3.webp",
        },
        {
          name: "Nautica",
          review: { score: 7.3, text: "Good" },
          passengers: 680,
          crew: 1000,
          cruises: 98,
          image: "/ShipCompany/Oceania-Cruise/ship-4.webp",
        },
        {
          name: "Regatta",
          review: { score: 8.8, text: "Good" },
          passengers: 684,
          crew: 1000,
          cruises: 59,
          image: "/ShipCompany/Oceania-Cruise/ship-5.webp",
        },
        {
          name: "Riviera",
          review: { score: 7.9, text: "Excellent" },
          passengers: 1250,
          crew: 1000,
          cruises: 71,
          image: "/ShipCompany/Oceania-Cruise/ship-6.webp",
        },
        {
          name: "Sirena",
          review: { score: 8.0, text: "Very Good" },
          passengers: 684,
          crew: 1000,
          cruises: 83,
          image: "/ShipCompany/Oceania-Cruise/ship-7.webp",
        },
        {
          name: "Vista",
          review: { score: "", text: "Very Good" },
          passengers: 1200,
          crew: 1000,
          cruises: 83,
          image: "/ShipCompany/Oceania-Cruise/ship-8.webp",
        },
      ],
    },
    {
      name: "Holland America Line",
      logo: "/ShipCompany/Holland-Cruise/holland-america-line-logo.cmjxg5oi.webp",
      modernizationYear: 2021,
      ships: [
        {
          name: "M/S Eurodam",
          review: { score: 7.2, text: "Good" },
          passengers: 2104,
          crew: 1000,
          cruises: 121,
          image: "/ShipCompany/Holland-Cruise/ship-1.webp",
        },
        {
          name: "M/S Koningsdam",
          review: { score: 8.3, text: "Very Good" },
          passengers: 2650,
          crew: 1000,
          cruises: 97,
          image: "/ShipCompany/Holland-Cruise/ship-2.webp",
        },
        {
          name: "M/S New Amsterdam",
          review: { score: 8.6, text: "Very Good" },
          passengers: 2106,
          crew: 1000,
          cruises: 144,
          image: "/ShipCompany/Holland-Cruise/ship-3.webp",
        },
        {
          name: "M/S Stantedam",
          review: { score: 8.2, text: "Very Good" },
          passengers: 2666,
          crew: 1000,
          cruises: 178,
          image: "/ShipCompany/Holland-Cruise/ship-4.webp",
        },
        {
          name: "M/S Noordam",
          review: { score: 7.5, text: "Very Good" },
          passengers: 1924,
          crew: 1000,
          cruises: 80,
          image: "/ShipCompany/Holland-Cruise/ship-5.webp",
        },
        {
          name: "M/S Oosterdam",
          review: { score: 7.7, text: "Very Good" },
          passengers: 3560,
          crew: 1916,
          cruises: 108,
          image: "/ShipCompany/Holland-Cruise/ship-6.webp",
        },
        {
          name: "M/S Rotterdam",
          review: { score: 8.5, text: "Very Good" },
          passengers: 2668,
          crew: 1000,
          cruises: 143,
          image: "/ShipCompany/Holland-Cruise/ship-7.webp",
        },
        {
          name: "M/S Vocendam",
          review: { score: "", text: "Very Good" },
          passengers: 1432,
          crew: 1000,
          cruises: 53,
          image: "/ShipCompany/Holland-Cruise/ship-8.webp",
        },
        {
          name: "M/S Westerdam",
          review: { score: 6.7, text: "Pleasant" },
          passengers: 1964,
          crew: 1000,
          cruises: 88,
          image: "/ShipCompany/Holland-Cruise/ship-9.webp",
        },
        {
          name: "M/S Zaandum",
          review: { score: 7.8, text: "Good" },
          passengers: 1432,
          crew: 1000,
          cruises: 76,
          image: "/ShipCompany/Holland-Cruise/ship-10.webp",
        },
        {
          name: "M/A Ziuderdum",
          review: { score: 7.4, text: "Very Good" },
          passengers: 1964,
          crew: 1000,
          cruises: 107,
          image: "/ShipCompany/Holland-Cruise/ship-11.webp",
        },
      ],
    },
    {
      name: "Celebrity Cruises",
      logo: "/ShipCompany/Celebrity-Cruise/celebrity-cruises.7ma2507h-logo.webp",
      modernizationYear: 2020,
      ships: [
        {
          name: "Celebrity Apex",
          review: { score: 8.3, text: "Very Good" },
          passengers: 2908,
          crew: 1000,
          cruises: 105,
          image: "/ShipCompany/Celebrity-Cruise/ship-1.webp",
        },
        {
          name: "Celebrity Ascent",
          review: { score: "", text: "Excellent" },
          passengers: 3260,
          crew: 1000,
          cruises: 79,
          image: "/ShipCompany/Celebrity-Cruise/ship-2.webp",
        },
        {
          name: "Celebrity Beyond",
          review: { score: 8.8, text: "Excellent" },
          passengers: 3260,
          crew: 1000,
          cruises: 114,
          image: "/ShipCompany/Celebrity-Cruise/ship-3.webp",
        },
        {
          name: "Celebrity Constellation",
          review: { score: 8.1, text: "Very Good" },
          passengers: 2034,
          crew: 1000,
          cruises: 79,
          image: "/ShipCompany/Celebrity-Cruise/ship-4.webp",
        },
        {
          name: "Celebrity Eclipse",
          review: { score: 8.2, text: "Very Good" },
          passengers: 2850,
          crew: 1000,
          cruises: 75,
          image: "/ShipCompany/Celebrity-Cruise/ship-5.webp",
        },
        {
          name: "Celebrity Edge",
          review: { score: 8.5, text: "Very Good" },
          passengers: 2918,
          crew: 1000,
          cruises: 91,
          image: "/ShipCompany/Celebrity-Cruise/ship-6.webp",
        },
        {
          name: "Celebrity Equinox",
          review: { score: 8.3, text: "Very Good" },
          passengers: 2850,
          crew: 1000,
          cruises: 84,
          image: "/ShipCompany/Celebrity-Cruise/ship-7.webp",
        },
        {
          name: "Celebrity Flora",
          review: { score: "", text: "Excellent" },
          passengers: 120,
          crew: 1000,
          cruises: 110,
          image: "/ShipCompany/Celebrity-Cruise/ship-8.webp",
        },
        {
          name: "Celebrity Infinity",
          review: { score: 8.2, text: "Very Good" },
          passengers: 2046,
          crew: 1000,
          cruises: 89,
          image: "/ShipCompany/Celebrity-Cruise/ship-9.webp",
        },
        {
          name: "Celebrity Millennium",
          review: { score: 8.2, text: "Very Good" },
          passengers: 2034,
          crew: 1000,
          cruises: 58,
          image: "/ShipCompany/Celebrity-Cruise/ship-10.webp",
        },
        {
          name: "Celebrity Reflection",
          review: { score: 8.5, text: "Excellent" },
          passengers: 3030,
          crew: 1000,
          cruises: 197,
          image: "/ShipCompany/Celebrity-Cruise/ship-11.webp",
        },
        {
          name: "Celebrity Solstice",
          review: { score: 8.1, text: "Very Good" },
          passengers: 2850,
          crew: 1000,
          cruises: 69,
          image: "/ShipCompany/Celebrity-Cruise/ship-12.webp",
        },
        {
          name: "Celebrity Summit",
          review: { score: 8.0, text: "Very Good" },
          passengers: 2034,
          crew: 1000,
          cruises: 84,
          image: "/ShipCompany/Celebrity-Cruise/ship-13.webp",
        },
        {
          name: "Celebrity Xcel",
          review: { score: "", text: "Very Good" },
          passengers: 3260,
          crew: 1000,
          cruises: 44,
          image: "/ShipCompany/Celebrity-Cruise/ship-14.webp",
        },
        {
          name: "Celebrity Xpedition",
          review: { score: "", text: "" },
          passengers: 90,
          crew: 1000,
          cruises: 6,
          image: "/ShipCompany/Celebrity-Cruise/ship-15.webp",
        },
        {
          name: "Celebrity Xploration",
          review: { score: "", text: "" },
          passengers: 16,
          crew: 1000,
          cruises: 6,
          image: "/ShipCompany/Celebrity-Cruise/ship-16.webp",
        },
        {
          name: "Celebrity Silhouette",
          review: { score: 8.6, text: "Excellent" },
          passengers: 2886,
          crew: 1000,
          cruises: 134,
          image: "/ShipCompany/Celebrity-Cruise/ship-17.webp",
        },
      ],
    },
    {
      name: "Explora Journeys",
      logo: "/ShipCompany/Explora-Cruise/explora-journeys-logo.2fponkfw.webp",
      modernizationYear: 2020,
      ships: [
        {
          name: "Explora I",
          review: { score: "", text: "Excellent" },
          passengers: 922,
          crew: 1000,
          cruises: 107,
          image: "/ShipCompany/Explora-Cruise/ship-1.webp",
        },
        {
          name: "Explora II",
          review: { score: "", text: "Excellent" },
          passengers: 922,
          crew: 1000,
          cruises: 75,
          image: "/ShipCompany/Explora-Cruise/ship-2.webp",
        },
        {
          name: "Explora III",
          review: { score: "", text: "Good" },
          passengers: 10,
          crew: 1000,
          cruises: "no cruises yet",
          image: "/ShipCompany/Explora-Cruise/ship-3.webp",
        },
      ],
    },
    {
      name: "Cunard Line",
      logo: "/ShipCompany/Cunard-Cruise/cunard-logo.8t9qxhnn.webp",
      modernizationYear: 2022,
      ships: [
        {
          name: "Queen Anne",
          review: { score: 8.1, text: "Very Good" },
          passengers: 2996,
          crew: 1000,
          cruises: 148,
          image: "/ShipCompany/Cunard-Cruise/ship-1.webp",
        },
        {
          name: "Queen Elizabeth",
          review: { score: 8.0, text: "Very Good" },
          passengers: 2081,
          crew: 1000,
          cruises: 122,
          image: "/ShipCompany/Cunard-Cruise/ship-2.webp",
        },
        {
          name: "Queen Mary 2",
          review: { score: 8.1, text: "Very Good" },
          passengers: 2620,
          crew: 1000,
          cruises: 193,
          image: "/ShipCompany/Cunard-Cruise/ship-3.webp",
        },
        {
          name: "Queen Victoria",
          review: { score: 8.2, text: "Very Good" },
          passengers: 1990,
          crew: 1000,
          cruises: 208,
          image: "/ShipCompany/Cunard-Cruise/ship-4.webp",
        },
      ],
    },
    {
      name: "Costa Cruises",
      logo: "/ShipCompany/Costa-Cruise/costa-kreuzfahrten.ezawl3rh-logo.webp",
      modernizationYear: 2017,
      ships: [
        {
          name: "Costa Deliziosa",
          review: { score: 7.3, text: "Very Good" },
          passengers: 2260,
          crew: 1000,
          cruises: 106,
          image: "/ShipCompany/Costa-Cruise/ship-1.webp",
        },
        {
          name: "Costa Diadema",
          review: { score: 7.6, text: "Good" },
          passengers: 3772,
          crew: 1000,
          cruises: 99,
          image: "/ShipCompany/Costa-Cruise/ship-2.webp",
        },
        {
          name: "Costa Favolosa",
          review: { score: 7.4, text: "Very Good" },
          passengers: 2860,
          crew: 1000,
          cruises: 153,
          image: "/ShipCompany/Costa-Cruise/ship-3.webp",
        },
        {
          name: "Costa Fortuna",
          review: { score: 6.6, text: "Pleasant" },
          passengers: 2860,
          crew: 1000,
          cruises: 116,
          image: "/ShipCompany/Costa-Cruise/ship-4.webp",
        },
        {
          name: "Costa Fascinosa",
          review: { score: 7.1, text: "Good" },
          passengers: 2716,
          crew: 1000,
          cruises: 253,
          image: "/ShipCompany/Costa-Cruise/ship-5.webp",
        },
        {
          name: "Costa Pacifia",
          review: { score: 7.1, text: "Good" },
          passengers: 2860,
          crew: 1000,
          cruises: 184,
          image: "/ShipCompany/Costa-Cruise/ship-6.webp",
        },
        {
          name: "Costa Serena",
          review: { score: "", text: "" },
          passengers: 2860,
          crew: 1000,
          cruises: 22,
          image: "/ShipCompany/Costa-Cruise/ship-7.webp",
        },
        {
          name: "Costa Smeralda",
          review: { score: 7.5, text: "Good" },
          passengers: 6218,
          crew: 1000,
          cruises: 245,
          image: "/ShipCompany/Costa-Cruise/ship-8.webp",
        },
        {
          name: "Costa Toscana",
          review: { score: 7.6, text: "Good" },
          passengers: 6218,
          crew: 1000,
          cruises: 236,
          image: "/ShipCompany/Costa-Cruise/ship-9.webp",
        },
      ],
    },
    {
      name: "Azamara",
      logo: "/ShipCompany/Azamara-Cruise/azamara-logo.4a2pcbbl.webp",
      modernizationYear: 2019,
      ships: [
        {
          name: "Azamara Journey",
          review: { score: 8.8, text: "Excellent" },
          passengers: 686,
          crew: 1000,
          cruises: 65,
          image: "/ShipCompany/Azamara-Cruise/ship-1.webp",
        },
        {
          name: "Azamara Onward",
          review: { score: 8.4, text: "Very Good" },
          passengers: 670,
          crew: 1000,
          cruises: 63,
          image: "/ShipCompany/Azamara-Cruise/ship-2.webp",
        },
        {
          name: "Azamara Pursuit",
          review: { score: 8.7, text: "Excellent" },
          passengers: 777,
          crew: 1000,
          cruises: 49,
          image: "/ShipCompany/Azamara-Cruise/ship-3.webp",
        },
        {
          name: "Azamara Quest",
          review: { score: 9.1, text: "Wonderful" },
          passengers: 686,
          crew: 1000,
          cruises: 79,
          image: "/ShipCompany/Azamara-Cruise/ship-4.webp",
        },
      ],
    },
    {
      name: "A-ROSA",
      logo: "/ShipCompany/Arosa-Cruise/a-rosa-logo.4p819nzf.webp",
      modernizationYear: 2014,
      ships: [
        {
          name: "A-ROSA Alea",
          review: { score: "", text: "Very Good" },
          passengers: 126,
          crew: 1000,
          cruises: 35,
          image: "/ShipCompany/Arosa-Cruise/ship-1.webp",
        },
        {
          name: "A-ROSA Alva",
          review: { score: 8.0, text: "Very Good" },
          passengers: 138,
          crew: 1000,
          cruises: 61,
          image: "/ShipCompany/Arosa-Cruise/ship-2.webp",
        },
        {
          name: "A-ROSA Bella",
          review: { score: 6.9, text: "Pleasant" },
          passengers: 202,
          crew: 1000,
          cruises: 66,
          image: "/ShipCompany/Arosa-Cruise/ship-3.webp",
        },
        {
          name: "A-ROSA Brava",
          review: { score: 8.0, text: "Very Good" },
          passengers: 242,
          crew: 1000,
          cruises: 72,
          image: "/ShipCompany/Arosa-Cruise/ship-4.webp",
        },
        {
          name: "A-ROSA Riva",
          review: { score: 7.3, text: "Very Good" },
          passengers: 242,
          crew: 1000,
          cruises: 67,
          image: "/ShipCompany/Arosa-Cruise/ship-5.webp",
        },
        {
          name: "A-ROSA Siena",
          review: { score: 8.0, text: "Very Good" },
          passengers: 242,
          crew: 1000,
          cruises: 72,
          image: "/ShipCompany/Arosa-Cruise/ship-6.webp",
        },
        {
          name: "A-ROSA Mia",
          review: { score: 7.8, text: "Very Good" },
          passengers: 280,
          crew: 1000,
          cruises: 67,
          image: "/ShipCompany/Arosa-Cruise/ship-7.webp",
        },
        {
          name: "A-ROSA Aqua",
          review: { score: 8.5, text: "Excellent" },
          passengers: 242,
          crew: 1000,
          cruises: 57,
          image: "/ShipCompany/Arosa-Cruise/ship-8.webp",
        },
      ],
    },
    {
      name: "Amadeus River Cruises",
      logo: "/ShipCompany/Amadus-Cruise/amadeus-logo-flusskreuzfahrten.5l52z6j9.webp",
      modernizationYear: 2015,
      ships: [
        {
          name: "M/S Amadeus Amara",
          review: { score: "", text: "Very Good" },
          passengers: 168,
          crew: 168,
          cruises: 4,
          image: "/ShipCompany/Amadus-Cruise/ship-1.webp",
        },
        {
          name: "M/S Amadeus Brilliant",
          review: { score: "", text: "Very Good" },
          passengers: 150,
          crew: 1000,
          cruises: 9,
          image: "/ShipCompany/Amadus-Cruise/ship-2.webp",
        },
        {
          name: "M/S Amadeus Diamond",
          review: { score: "", text: "Very Good" },
          passengers: 170,
          crew: 1000,
          cruises: 23,
          image: "/ShipCompany/Amadus-Cruise/ship-3.webp",
        },
        {
          name: "M/S Amadeus Imperial",
          review: { score: "", text: "Very Good" },
          passengers: 150,
          crew: 1000,
          cruises: 18,
          image: "/ShipCompany/Amadus-Cruise/ship-4.webp",
        },
        {
          name: "M/S Amadeus Nova",
          review: { score: "", text: "Very Good" },
          passengers: 168,
          crew: 1000,
          cruises: 4,
          image: "/ShipCompany/Amadus-Cruise/ship-5.webp",
        },
        {
          name: "M/S Amadeus Provence",
          review: { score: "", text: "Good" },
          passengers: 158,
          crew: 1000,
          cruises: 16,
          image: "/ShipCompany/Amadus-Cruise/ship-6.webp",
        },
        {
          name: "M/S Amadeus Queen",
          review: { score: "", text: "Very Good" },
          passengers: 140,
          crew: 1000,
          cruises: 20,
          image: "/ShipCompany/Amadus-Cruise/ship-7.webp",
        },
        {
          name: "M/S Amadeus Riva",
          review: { score: "", text: "Excellent" },
          passengers: 162,
          crew: 1000,
          cruises: 14,
          image: "/ShipCompany/Amadus-Cruise/ship-8.webp",
        },
        {
          name: "M/S Amadeus Silver II",
          review: { score: "", text: "Very Good" },
          passengers: 168,
          crew: 1000,
          cruises: 25,
          image: "/ShipCompany/Amadus-Cruise/ship-9.webp",
        },
        {
          name: "M/S Amadeus Silver III",
          review: { score: "", text: "Very Good" },
          passengers: 168,
          crew: 1000,
          cruises: "no cruises yet",
          image: "/ShipCompany/Amadus-Cruise/ship-10.webp",
        },
        {
          name: "M/S Amadeus Star",
          review: { score: "", text: "Good" },
          passengers: 164,
          crew: 1000,
          cruises: 10,
          image: "/ShipCompany/Amadus-Cruise/ship-11.webp",
        },
      ],
    },
  ],
};

export default cruiseData;
