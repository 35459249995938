import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaCheck, FaTimes, FaArrowRight } from "react-icons/fa";
import ships from "../../constants/ships";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "react-datepicker/dist/react-datepicker.css";
import "./CruiseDetails.css";

const CruiseDetails = () => {
  const { cruiseName } = useParams();
  const navigate = useNavigate();

  const cruise = ships.recommendations.find(
    (c) => c.shipName.toLowerCase().replace(/ /g, "-") === cruiseName
  );

  const [showItinerary, setShowItinerary] = useState(false);

  const handleBookingClick = () => {
    navigate(`/${cruise.shipName.toLowerCase().replace(/ /g, "-")}-booking`, {
      state: {
        shipName: cruise.shipName,
        direction: cruise.description,
        price: cruise.prices[0]?.amount,
        transportationOptions: cruise.transportationOptions,
        prices: cruise.prices,
        currency: cruise.currency || "$", // Default to "$" if no currency is provided
      },
    });
  };

  if (!cruise) {
    return <div>Cruise not found</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="sticky-overview">
        <div className="sticky-text">
          <h2>{cruise.shipName}</h2>
          <p className="cruise-price-pick">
            From {cruise.currency || "$"} {cruise.prices[0]?.amount} / Cabin
          </p>
        </div>
        <div className="sticky-layout">
          <p className="cruise-date-pick">From {cruise.date}</p>
          <div>
            <button className="booking-button" onClick={handleBookingClick}>
              To Booking
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="cruise-detailss">
        <h1>{cruise.shipName}</h1>
        <div className="cruise-immages">
          <img
            src={cruise.image}
            alt={cruise.shipName}
            className="cruise-main-image"
          />
          {cruise.others && cruise.others.length > 0 && (
            <div className="cruise-other-images">
              {cruise.others.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${cruise.shipName} view ${index + 1}`}
                  className="cruise-other-image"
                />
              ))}
            </div>
          )}
        </div>
        <p className="cruise-description">{cruise.description}</p>

        {/* View Itinerary Section */}
        <div className="itinerary-section">
          <button
            className="view-itinerary-button"
            onClick={() => setShowItinerary(!showItinerary)}
          >
            {showItinerary ? "Hide Itinerary" : "View Itinerary"}
          </button>
          {showItinerary && (
            <ul className="itinerary-list">
              {cruise.itinerary.map((item, index) => (
                <li key={index} className="itinerary-item">
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Price Overview Section */}
        <div className="price-overview">
          <h2>Price Overview</h2>
          <div className="price-table">
            {cruise.prices.map((price, index) => (
              <div key={index} className="price-row">
                <span className="room-type">{price.type}</span>
                <span className="price">
                  from {cruise.currency || "$"} {price.amount} per Person
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Included in Cruise Price Section */}
        <div className="cruise-inclusions">
          <h2>Included in Cruise Price</h2>
          <ul className="included-benefits">
            {cruise.included.map((item, index) => (
              <li key={index} className="benefit">
                <FaCheck className="benefit-icon" /> {item}
              </li>
            ))}
          </ul>
          <h3>Not Included</h3>
          <p className="not-included">
            <FaTimes className="cancel-icon" /> {cruise.notIncluded}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CruiseDetails;
