import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/Homepage";
import CruiseDetails from "./components/CruiseDetails/CruiseDetails";
import CompanyShipList from "./components/CompanyShipPage/CompanyShipList";
import ShipDetails from "./components/ShipDetails/ShipDetails";
import BookingPage from "./components/Booking/Booking";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import RecommendationsPage from "./components/Recommendation/Recommendation";
import ships from "./constants/ships";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/company-ships" element={<CompanyShipList />} />
          <Route path="/cruise/:cruiseName" element={<CruiseDetails />} />
          <Route path="/ship/:shipName" element={<ShipDetails />} />{" "}
          <Route path="*" element={<HomePage />} />
          <Route path="/:shipName-booking" element={<BookingPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route
            path="/recommendations"
            element={<RecommendationsPage ships={ships.recommendations} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
