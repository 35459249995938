import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Booking.css";
import { FaBus, FaPlane, FaParking } from "react-icons/fa";
import DatePicker from "react-datepicker";
const BookingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const bookingData = location.state;

  // Redirect back if no data is passed
  useEffect(() => {
    if (!bookingData) {
      navigate(-1);
    }
  }, [bookingData, navigate]);

  const [startDate, setStartDate] = useState(new Date("2024-12-14"));
  const [endDate, setEndDate] = useState(new Date());
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCabin, setSelectedCabin] = useState("");
  const [selectedTransports, setSelectedTransports] = useState([]);
  const [numTravelers, setNumTravelers] = useState(1);
  const [isChild, setIsChild] = useState(Array(numTravelers).fill(false));
  // Default to an empty string or some valid ship name
  // Track if each traveler is a child

  const [selectedClass, setSelectedClass] = useState("");
  const [travelerInfo, setTravelerInfo] = useState([
    {
      salutation: "",
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  ]);

  const [activeDropdown, setActiveDropdown] = useState(null); // State for open dropdown

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const newEndDate = new Date(date);
    newEndDate.setDate(newEndDate.getDate() + 7);
    setEndDate(newEndDate);
  };
  const selectedCurrency = bookingData?.currency || "$"; // Or derive this dynamically

  // Handle data submission to Formspree in step 3
  useEffect(() => {
    if (currentStep === 3) {
      const formData = travelerInfo.map((traveler, index) => ({
        ...traveler,
        cabin: selectedCabin,
        transport: selectedTransports,
        class: selectedClass,
        travelerIndex: index + 1,
      }));

      // Submit data to Formspree
      const submitToFormspree = async () => {
        try {
          const response = await fetch("https://formspree.io/f/mjkvalrb", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });

          if (!response.ok) {
            console.error(
              "Error submitting data to Formspree:",
              response.statusText
            );
          } else {
            console.log("Data successfully submitted to Formspree.");
          }
        } catch (error) {
          console.error("Error connecting to Formspree:", error);
        }
      };

      submitToFormspree();
    }
  }, [
    currentStep,
    travelerInfo,
    selectedCabin,
    selectedTransports,
    selectedClass,
  ]);

  if (!bookingData) return null;

  const { shipName, direction, price, transportationOptions, prices } =
    bookingData;

  const handleTravelerChange = (index, field, value) => {
    const updatedTravelers = [...travelerInfo];

    // Ensure traveler object exists at the specified index
    if (!updatedTravelers[index]) {
      updatedTravelers[index] = {
        salutation: "", // Initialize required default fields
        firstName: "",
        lastName: "",
        dob: "",
      };
    }

    // Update the specified field
    updatedTravelers[index][field] = value;

    // Update the state
    setTravelerInfo(updatedTravelers);
  };

  const handleTransportChange = (type) => {
    setSelectedTransports((prev) =>
      prev.includes(type)
        ? prev.filter((transport) => transport !== type)
        : [...prev, type]
    );
    if (selectedTransports.includes(type)) {
      setSelectedClass(""); // Reset class when deselecting transport
    }
  };

  const toggleDropdown = (type) => {
    setActiveDropdown((prev) => (prev === type ? null : type)); // Toggle dropdown
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="step-1">
            <h2>Select Cabin Type</h2>
            <p>Ship Name: {shipName}</p>
            <p>Direction: {direction}</p>
            <div className="date-picker">
              <label className="cruise-date">
                Select Cruise Date:{" "}
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                />
              </label>

              <div className="date-displayText">
                <span>From: {startDate.toLocaleDateString()}</span>
                <span>To: {endDate.toLocaleDateString()}</span>
              </div>
            </div>
            <p>
              Base Price: {selectedCurrency}
              {price}
            </p>

            <div className="cabins">
              {prices?.map((price, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    id={price.type.toLowerCase()}
                    name="cabin"
                    value={price.type}
                    onChange={(e) => setSelectedCabin(e.target.value)}
                  />
                  <label htmlFor={price.type.toLowerCase()}>
                    {price.type} Cabin: {selectedCurrency}
                    {price.amount}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="step-2">
            <h2>Please Select Your Travel Options</h2>
            <div className="transportation-header">
              <h3>Transportation</h3>
              <div>
                <input
                  type="checkbox"
                  id="no-transportation"
                  onChange={() => {
                    if (selectedTransports.length === 0) {
                      setSelectedTransports(["placeholder"]);
                    } else {
                      setSelectedTransports([]);
                      setActiveDropdown(null);
                      setSelectedClass("");
                    }
                  }}
                  checked={selectedTransports.length === 0}
                />
                <label htmlFor="no-transportation">No Transportation</label>
              </div>
            </div>
            <div className="transportation-options">
              {transportationOptions && transportationOptions.length > 0 ? (
                transportationOptions.map((transport) => (
                  <div
                    key={transport.type}
                    className={`transport-option ${
                      selectedTransports.includes(transport.type)
                        ? "active"
                        : ""
                    }`}
                  >
                    <div
                      className="dropdown-toggle"
                      onClick={() => toggleDropdown(transport.type)}
                    >
                      <input
                        type="checkbox"
                        id={transport.type}
                        onChange={() => handleTransportChange(transport.type)}
                        checked={selectedTransports.includes(transport.type)}
                      />
                      {transport.type === "Railway" && <FaBus />}
                      {transport.type === "Air" && <FaPlane />}
                      {transport.type === "Parking" && <FaParking />}
                      <label htmlFor={transport.type}>{transport.type}</label>
                    </div>

                    {activeDropdown === transport.type && (
                      <div className="dropdown-content">
                        <h3>Choose a Class for {transport.type}</h3>
                        {transport.options.length > 0 ? (
                          transport.options.map((option) => (
                            <div key={option.class} className="class-option">
                              <input
                                type="radio"
                                id={`${transport.type}-${option.class}`}
                                name={transport.type}
                                value={option.class}
                                onChange={() => setSelectedClass(option.class)}
                                checked={selectedClass === option.class}
                              />
                              <label
                                htmlFor={`${transport.type}-${option.class}`}
                              >
                                {option.class}: {selectedCurrency}
                                {option.price}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>No class options available for {transport.type}</p>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No transportation options available</p>
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step">
            <h2>Traveler Information</h2>

            {/* Traveler toggle buttons */}
            <div className="traveler-toggle">
              <label>Number of Travelers: </label>
              <button onClick={() => setNumTravelers(numTravelers + 1)}>
                Add Traveler
              </button>
              <button
                onClick={() => setNumTravelers(Math.max(1, numTravelers - 1))}
              >
                Remove Traveler
              </button>
            </div>

            {/* Render traveler info fields */}
            {Array.from({ length: numTravelers }).map((_, index) => (
              <div key={index} className="traveler-info">
                <h3>Traveler {index + 1}</h3>

                {/* Salutation */}
                <select
                  value={travelerInfo[index]?.salutation || ""}
                  onChange={(e) =>
                    handleTravelerChange(index, "salutation", e.target.value)
                  }
                >
                  <option value="">Salutation</option>
                  <option value="mr">Mr.</option>
                  <option value="mrs">Mrs.</option>
                  <option value="miss">Miss</option>
                  <option value="dr">Dr.</option>
                </select>

                {/* First Name */}
                <input
                  type="text"
                  placeholder="First Name"
                  value={travelerInfo[index]?.firstName || ""}
                  onChange={(e) =>
                    handleTravelerChange(index, "firstName", e.target.value)
                  }
                />

                {/* Last Name */}
                <input
                  type="text"
                  placeholder="Last Name"
                  value={travelerInfo[index]?.lastName || ""}
                  onChange={(e) =>
                    handleTravelerChange(index, "lastName", e.target.value)
                  }
                />

                {/* Date of Birth */}
                <input
                  type="date"
                  value={travelerInfo[index]?.dob || ""}
                  onChange={(e) =>
                    handleTravelerChange(index, "dob", e.target.value)
                  }
                />

                {/* Additional Information for the first traveler */}
                {index === 0 && (
                  <>
                    <input
                      type="email"
                      placeholder="Email"
                      value={travelerInfo[index]?.email || ""}
                      onChange={(e) =>
                        handleTravelerChange(index, "email", e.target.value)
                      }
                    />
                    <textarea
                      placeholder="Address"
                      value={travelerInfo[index]?.address || ""}
                      onChange={(e) =>
                        handleTravelerChange(index, "address", e.target.value)
                      }
                    />
                    <input
                      type="text"
                      placeholder="City"
                      value={travelerInfo[index]?.city || ""}
                      onChange={(e) =>
                        handleTravelerChange(index, "city", e.target.value)
                      }
                    />
                    <input
                      type="text"
                      placeholder="State"
                      value={travelerInfo[index]?.state || ""}
                      onChange={(e) =>
                        handleTravelerChange(index, "state", e.target.value)
                      }
                    />
                    <input
                      type="text"
                      placeholder="Country"
                      value={travelerInfo[index]?.country || ""}
                      onChange={(e) =>
                        handleTravelerChange(index, "country", e.target.value)
                      }
                    />
                    <input
                      type="text"
                      placeholder="ZIP Code"
                      value={travelerInfo[index]?.zip || ""}
                      onChange={(e) =>
                        handleTravelerChange(index, "zip", e.target.value)
                      }
                    />
                  </>
                )}

                {/* Checkbox for Is Child */}
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChild[index] || false}
                      onChange={() => {
                        const updatedIsChild = [...isChild];
                        updatedIsChild[index] = !updatedIsChild[index];
                        setIsChild(updatedIsChild);
                      }}
                    />
                    Is this traveler a child?
                  </label>
                </div>
              </div>
            ))}
          </div>
        );

      case 4:
        const calculatePrice = () => {
          let totalPrice = 0;
          let travelerPrices = [];

          // Cabin Price
          const cabinPrice =
            prices?.find((price) => price.type === selectedCabin)?.amount || 0;

          // Transportation Price
          const transportPrice = selectedTransports.reduce(
            (acc, transportType) => {
              const transport = transportationOptions?.find(
                (option) => option.type === transportType
              );
              const selectedOption = transport?.options?.find(
                (option) => option.class === selectedClass
              );
              return acc + (selectedOption?.price || 0);
            },
            0
          );

          // Calculate prices for each traveler
          travelerInfo.forEach((_, index) => {
            let travelerPrice = cabinPrice + transportPrice;

            if (isChild[index]) {
              travelerPrice /= 2; // 50% discount for children
            }

            travelerPrices.push({
              traveler: index + 1,
              price: `${selectedCurrency}${travelerPrice.toFixed(2)}`,
            });

            totalPrice += travelerPrice;
          });

          return {
            totalPrice: `${selectedCurrency}${totalPrice.toFixed(2)}`,
            travelerPrices,
          };
        };

        const { totalPrice, travelerPrices } = calculatePrice();

        return (
          <div className="step">
            <h2>Payment</h2>
            <p>
              <strong>Total Price: </strong>
              {/* Display the total price as a string, no need to modify it */}
              {totalPrice || "$0.00"}
            </p>

            <ul>
              {travelerPrices.map((traveler) => (
                <li key={traveler.traveler}>
                  <strong>Traveler {traveler.traveler}:</strong>
                  {/* Directly display the traveler price as it is (with currency symbol) */}
                  {traveler.price || "$0.00"}
                </li>
              ))}

              {/* Selected Cabin */}
              <li>
                <strong>Selected Cabin ({selectedCabin || "None"}):</strong>
                {(() => {
                  const foundCabin = prices.find(
                    (price) => price.type === selectedCabin
                  );

                  // If no cabin is found or the price is 'sold out', return 'N/A'
                  if (!foundCabin) {
                    return "N/A";
                  }

                  // Directly return the cabin price (which is already a string with the currency symbol)
                  return foundCabin.amount === "sold out"
                    ? "Sold Out"
                    : `${selectedCurrency}${foundCabin.amount}`;
                })()}
              </li>

              {/* Selected Transportation */}
              {selectedTransports.length > 0 ? (
                selectedTransports.map((transportType) => {
                  const transport = transportationOptions.find(
                    (option) => option.type === transportType
                  );
                  const selectedOption = transport?.options.find(
                    (option) => option.class === selectedClass
                  );

                  return (
                    <li key={transportType}>
                      <strong>
                        {transportType} ({selectedClass}):
                      </strong>{" "}
                      {/* Display transportation price as a string (with currency symbol) */}
                      {selectedOption?.price
                        ? `${selectedCurrency}${selectedOption.price}`
                        : "$0.00"}
                    </li>
                  );
                })
              ) : (
                <li>No transportation selected.</li>
              )}
            </ul>

            <button
              className="payment-button"
              onClick={() =>
                navigate(
                  `/payment?amount=${totalPrice.replace(
                    /[^0-9.-]+/g,
                    ""
                  )}&currency=${selectedCurrency}`
                )
              }
            >
              Make Payment
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="booking-page">
      <h1>Booking for {shipName}</h1>
      <div className="step-content">{renderStepContent()}</div>
      <div className="navigation-buttons">
        <button
          className="back-button"
          disabled={currentStep === 1}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </button>
        <button
          className="next-button"
          disabled={currentStep === 4}
          onClick={() => setCurrentStep(currentStep + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BookingPage;
