import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import ships from "../constants/ships";
import CruiseCard from "../components/CruiseCard/CruiseCard";
import FAQ from "../components/FAQ/FAQ";
import CruiseAdvisor from "../components/CruiseAdvisor/CruiseAdvisor";
import Footer from "../components/Footer/Footer";
import "./homepage.css";
import CompanyList from "../components/ShipsCompany/CompanyList";
import Newsletter from "../components/Newsletter/Newsletter";
import PopupModal from "../components/PopupModal/PopupModal"; // Import the modal component

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(true); // Control modal visibility
  const featuredShip = ships.recommendations[6]; // Choose a featured cruise card

  return (
    <div>
      <Navbar />
      <div className="nav-image"></div>
      <CruiseCard ships={ships.recommendations} />
      <FAQ />
      <CompanyList />
      <Newsletter />
      <CruiseAdvisor />
      <Footer />
      {/* Render the popup if showPopup is true */}
      {showPopup && (
        <PopupModal
          ship={featuredShip}
          onClose={() => setShowPopup(false)} // Close the modal
        />
      )}
    </div>
  );
};

export default HomePage;
