import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaArrowCircleRight, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./CruiseCard.css";

const CruiseCard = ({ ships }) => {
  const navigate = useNavigate();

  const handleMoreInfo = (ship) => {
    const slug = ship.shipName.toLowerCase().replace(/\s+/g, "-");
    navigate(`/cruise/${slug}`);
  };
  const handleRecommendationsClick = () => {
    navigate("/recommendations");
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4, // Show 4 cards for desktop
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2, // Show 2 cards for tablets
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1, // Show 1 card on mobile
      slidesToSlide: 1,
    },
  };

  return (
    <div>
      <div>
        <h4
          onClick={handleRecommendationsClick}
          style={{
            cursor: "pointer",
            marginLeft: "0.5rem",
            display: "flex",
            marginTop: "0.5rem",
            alignItems: "center",
          }}
        >
          Our Recommendations
          <span style={{ marginLeft: "0.5rem", display: "flex" }}>
            <FaArrowRight />
          </span>
        </h4>
      </div>
      <div className="cruises-container">
        <div className="cruise-cards-container">
          <Carousel
            swipeable
            draggable
            showDots={false}
            responsive={responsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={3000}
            keyBoardControl
            customTransition="transform 300ms ease-in-out"
            transitionDuration={300}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-0-px"
          >
            {ships.map((ship, index) => (
              <div
                key={index}
                className="cruise-card"
                onClick={() => handleMoreInfo(ship)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={ship.image}
                  alt={ship.shipName}
                  className="cruise-image"
                />
                <div className="cruise-details">
                  <h3>{ship.shipName}</h3>
                  <p>{ship.heading}</p>
                  <p>{ship.description}</p>
                  <div className="cruise-price-row">
                    <p>
                      from <strong>{ship.price}</strong> per Person
                    </p>
                    <button
                      className="info-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoreInfo(ship);
                      }}
                    >
                      <FaArrowCircleRight />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CruiseCard;
