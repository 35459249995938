import React from "react";
import { useParams } from "react-router-dom";
import cruiseData from "../../constants/ShipCompany";
import "./ShipDetails.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
const ShipDetails = () => {
  const { shipName } = useParams();

  // Find the specific ship and its company
  const ship = cruiseData.companies
    .flatMap((company) => company.ships)
    .find((ship) => ship.name === shipName);

  if (!ship) return <div>Ship not found!</div>;

  const company = cruiseData.companies.find((company) =>
    company.ships.includes(ship)
  );

  // Generate dynamic descriptions
  const shortDescription = `${ship.name} from ${company.name} : Everything you need to know:`;
  const detailedDescription = `The ${ship.name} is one of the smaller ships in the fleet of ${company.name}, accommodating a maximum of ${ship.passengers} passengers.
A standout feature of this ship is that almost everything is included in the cruise price, even premium drinks such as fine wines and real champagne.
In ${company.modernizationYear}, ${ship.name} underwent a comprehensive modernization, during which its interior design was updated to reflect ${company.name}'s high standards.
This refurbishment included a complete redesign of the public areas and select suites, ensuring a fresh, contemporary look that enhances the onboard experience.`;

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="ship-details">
        <h1>{ship.name}</h1>
        <img src={ship.image} alt={ship.name} className="ship-detail-image" />
        <p>{company.name}</p>
        <div>
          <h3>About the Ship</h3>
          <p>{shortDescription}</p> {/* Dynamic short description */}
          <p>{detailedDescription}</p> {/* Dynamic detailed description */}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ShipDetails;
