import React from "react";
import "./CruiseAdvisor.css";

const CruiseAdvisor = () => {
  return (
    <div className="cruise-container">
      <section className="cruise-section">
        <h2 className="cruise-section-title">
          Book your cruise with the experts
        </h2>
        <p className="cruise-section-text">
          Cruise Advisor offers over 20,000 cruises worldwide. Easily compare
          routes and prices and discover the most beautiful cruise destinations.
          No matter whether you are looking for river cruises or ocean voyages:
          We have the right offer for you. Discover the offers of the popular
          cruise lines AIDA, Mein Schiff, Phoenix Reisen, MSC, and Costa as well
          as many other national and international providers on our website. We
          can advise you on destinations, shipping companies, and suitable
          ships. You can also book your cruise with us as a package, including
          flights. So you can start your well-deserved cruise holiday in
          complete relaxation.
        </p>
      </section>
      <section className="cruise-section">
        <h2 className="cruise-section-title">
          Discover the most popular cruise destinations
        </h2>
        <p className="cruise-section-text">
          How would it be if you could discover the dream cities of the western
          Mediterranean on a single trip? Visit the Cathedral of Palma, stroll
          along La Rambla in Barcelona and lick an Italian ice cream in Rome - a
          cruise makes it possible. It's not for nothing that the Mediterranean
          is one of the most popular cruise destinations. There are many offers
          for Mediterranean cruises, especially in spring, summer, and autumn.
          Just as popular for the same travel time, but with a more convenient
          journey: Norway cruises. Immerse yourself in the fabulous world of the
          fjords, experience the midnight sun, and discover magical coastal
          towns. When it gets colder in Germany, the Canaries lure in with their
          spring-like temperatures. A break is particularly worthwhile in autumn
          and winter when you can soak up an extra portion of sun here. By the
          way: Many Canary Islands cruises stop on the flower island of Madeira
          - you shouldn't miss it! Are you dreaming of turquoise water, endless
          sandy beaches, and a tropical flair? Then Caribbean cruises in winter
          are the right choice for you. We will be happy to advise you if you
          don't yet know which destination is right for you.
        </p>
      </section>
      <section className="cruise-section">
        <h2 className="cruise-section-title">Find cruise offers and deals</h2>
        <p className="cruise-section-text">
          Your chance of getting a bargain increases immensely if you are
          flexible in terms of travel period, duration, and shipping company.
          You can often find last minute cruise offers, especially for
          short-term departures. Are you afraid of traveling by plane? Then
          discover a variety of cruises from Germany with us. By the way: Many
          shipping companies offer deals for family cruises (including discounts
          for children and/or special attractions on board), so a cruise is the
          ideal form of travel for young and old. Are you traveling alone? Then
          discover offers for single cruises with us and go on a voyage of
          discovery! Are you still not sure whether a cruise is right for you?
          Then try a mini cruise and see for yourself the variety of offers on
          board. If you dream of traveling around the world in as much comfort
          as possible, you will find what you are looking for on our world
          cruises.
        </p>
      </section>{" "}
    </div>
  );
};

export default CruiseAdvisor;
