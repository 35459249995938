import React, { useState } from "react";
import { FaChevronDown, FaChevronUp, FaCheck } from "react-icons/fa"; // Import FaCheck icon
import "./FAQ.css";
import logo from "../../images/log-1.png";
const FAQ = () => {
  // Questions and answers data
  const faqData = [
    {
      question: "What are the most popular cruise destinations?",
      answer:
        "The most popular cruise destinations include Norway and the Mediterranean. Many Norway cruises depart from Germany, so you can easily explore the Norwegian fjords. In the winter months, warm destinations such as the Canary Islands or the Caribbean are ideal.",
    },
    {
      question: "Which cruise line is right for me?",
      answer:
        "Not sure which shipping company is right for you? Cruises are versatile and have something for everyone. If you want to travel in German, then Mein Schiff, AIDA, Phoenix Reisen, and Hapag-Lloyd Cruises are a good choice. If you like an international atmosphere on board, shipping companies such as MSC, Costa, Royal Caribbean, and NCL will provide you with the right ambiance. Our cruise experts will be happy to answer your questions and requests almost 365 days a year.",
    },
    {
      question: "How do I find the cheapest price?",
      answer:
        "To find the best deals, you can search for last-minute offers, compare prices on various booking platforms, and take advantage of early-bird discounts and seasonal promotions.",
    },
    {
      question: "Can I book my cruise online?",
      answer:
        "Yes, with Seacruisetravels, booking your dream cruise online is quick and easy. Simply select your preferred destination, travel dates, and cabin type, and complete the booking process to set sail on your next adventure!",
    },
  ];

  // State to keep track of the active question
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggles the active question
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-banner">
        <div className="banner-content">
          <div className="banner-heading">
            <img src={logo} alt="company-logo" />
            <h1>We are SeascruiseTravels, WE are the experts!</h1>
          </div>
          <ul className="banner-features">
            <li>
              <FaCheck className="icon-check" /> Select your desired cabin in
              detailed deck plans
            </li>
            <li>
              <FaCheck className="icon-check" /> Becoming Germany's top cruise
              portal with exceptional service.
            </li>
            <li>
              <FaCheck className="icon-check" /> Book your dream trip easily and
              conveniently online
            </li>
            <li>
              <FaCheck className="icon-check" /> A personal service from our
              cruise experts 7 days a week via email
            </li>
          </ul>
        </div>
      </div>

      {/* FAQ section */}
      <h2>Frequently Asked Questions</h2>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div
            className="faq-question"
            onClick={() => handleToggle(index)}
            style={{ cursor: "pointer", fontWeight: "bold", margin: "10px 0" }}
          >
            {item.question}
            <span style={{ marginLeft: "10px" }}>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="faq-answer" style={{ padding: "5px 15px" }}>
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
