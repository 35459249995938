import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import cruiseData from "../../constants/ShipCompany";
import "./CompanyShipList.css";
import Footer from "./../Footer/Footer";
import { FaHome } from "react-icons/fa";

const CompanyShipList = () => {
  // Scroll to the appropriate section when the component loads
  useEffect(() => {
    const hash = decodeURIComponent(window.location.hash); // Decode hash
    if (hash) {
      // Escape invalid characters in the hash before using querySelector
      const sanitizedHash = hash.replace(
        /[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g,
        "\\$&"
      );
      const element = document.querySelector(sanitizedHash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div>
      <div className="upper-text">
        <p>Good to know: Our prices always include taxes and port charges!</p>
      </div>
      <div className="home-icon">
        <Link to="/" aria-label="Go to Home">
          <FaHome size={20} />
        </Link>
      </div>
      <div className="company-ship-list">
        {/* Navigation Section for Company Logos with Names */}
        <div className="company-list">
          {cruiseData.companies.map((company, index) => (
            <div key={index} className="company-card">
              <a
                href={`#${encodeURIComponent(company.name)}`}
                className="company-logo-link"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior if needed
                  const element = document.getElementById(
                    encodeURIComponent(company.name)
                  );
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                <img src={company.logo} alt={`${company.name} logo`} />
                <h3>{company.name}</h3>
              </a>
            </div>
          ))}
        </div>

        {/* Ship Tables for Each Company */}
        <div className="ship-tables">
          {cruiseData.companies.map((company, index) => (
            <div
              key={index}
              id={encodeURIComponent(company.name)}
              className="company-section"
            >
              {/* Company Header */}
              <div className="company-header">
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  className="company-logo"
                />
                <h2>{company.name}</h2>
              </div>

              {/* Ship Details Table */}
              <table className="ship-table">
                <thead>
                  <tr>
                    <th>Ship</th>
                    <th>Review</th>
                    <th>Passengers</th>
                    <th className="cruise-line">Cruise Line</th>
                    <th>Cruises</th>
                  </tr>
                </thead>
                <tbody>
                  {company.ships.map((ship, idx) => (
                    <tr key={idx}>
                      <td className="image-text">
                        {/* Wrap the ship name with the Link component */}
                        <Link
                          to={`/ship/${ship.name}`}
                          className="ship-name-link"
                        >
                          <img
                            src={ship.image}
                            alt={ship.name}
                            className="ship-image"
                          />
                          {ship.name}
                        </Link>
                      </td>

                      <td>
                        <strong>{ship.review.score}</strong> {ship.review.text}
                      </td>
                      <td>{ship.passengers}</td>
                      <td className="cruise-line">{company.name}</td>
                      <td>{ship.cruises} Cruises</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CompanyShipList;
