import React from "react";
import { Link } from "react-router-dom";
import cruiseData from "../../constants/ShipCompany";
import { FaShip } from "react-icons/fa"; // React Icon for the button
import "./CompanyList.css";

const CompanyList = () => {
  return (
    <div className="company-list-container">
      <div className="partners-section">
        <h2>Our Partners</h2>
        <div className="all-cruise-lines">
          <Link to="/company-ships" className="all-cruise-link">
            <FaShip className="cruise-icon" />
            All Cruise Lines
          </Link>
        </div>
      </div>

      {/* Company Logos */}
      <div className="company-list">
        {cruiseData.companies.map((company, index) => (
          <div key={index} className="company-card">
            <Link to={`/company-ships#${encodeURIComponent(company.name)}`}>
              <img src={company.logo} alt={`${company.name} logo`} />
              <h3>{company.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyList;
