import React from "react";
import { useNavigate } from "react-router-dom";
import "./PopupModal.css";
import pic1 from "../../images/favourite/pic-21.jpg";

const PopupModal = ({ ship, onClose }) => {
  const navigate = useNavigate();

  const handleMoreInfo = (ship) => {
    const slug = ship.shipName.toLowerCase().replace(/\s+/g, "-");
    navigate(`/cruise/${slug}`); // Use backticks here
  };

  if (!ship) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modall-content" onClick={(e) => e.stopPropagation()}>
        <button className="closse-button" onClick={onClose}>
          &times;
        </button>
        <p>Book now and enjoy up to $500 OFF your cruise!</p>
        <img src={ship.image} alt={ship.shipName} className="modal-image" />
        <h3>{ship.shipName}</h3>
        <p className="ship-head">{ship.heading}</p>
        <p>{ship.description}</p>
        <p class="strikethrough">Originally $1250</p>

        <p>
          But now only <strong>{ship.price}</strong> per person!
        </p>

        <button
          className="book-now-button"
          onClick={() => handleMoreInfo(ship)}
        >
          Book Now
        </button>
      </div>
    </div>
  );
};

export default PopupModal;
