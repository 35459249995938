import React from "react";
import logo from "../../images/secure-icon.svg";
import secureIcon from "../../images/secure-icon.svg";
import visa from "../../images/payment-cards.png";
import crypto from "../../images/payment-crypto.png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer id="Footer">
      <div className="footer-top">
        <div className="logoo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="socials">
          <a
            href="https://x.com/seacruisetrvs?s=21 "
            className="twitter-icon"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/seacruisetrvs?igsh=MWg4bGFuMXRnbzVzMA%3D%3D&utm_source=qr
"
            className="instagram-icon"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/share/gmaaCmBbnTSZGhdg/?mibextid=LQQJ4d
"
            className="facebook-icon"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="mailto:seacruisetravels.info@gmail.com?subject=Inquiry&body=Hello, I would like to know more about your cruises."
            className="gmail-icon"
            aria-label="Gmail"
          >
            <i className="fas fa-envelope"></i>
          </a>
        </div>
      </div>

      <div className="links">
        <ul>
          <li>
            <h2>Terms & Info</h2>
          </li>
          <li>
            <a href="#terms">Terms & Conditions</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            <a href="#privacy">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="footer-payments">
        <img src={secureIcon} alt="Secure SSL Encryption" />
        <img src={visa} alt="Visa" />
        <img src={crypto} alt="Cryptocurrency" />
      </div>
      <p className="copyright-text">© 2024 SeacruiseTravels</p>
    </footer>
  );
};

export default Footer;
