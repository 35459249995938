import React, { useState } from "react";
import "./Newsletter.css";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email) {
      // FormSubmit automatically handles the email submission and response.
      setMessage(
        "Thank you for subscribing! Check your email for confirmation."
      );
      setEmail("");
    } else {
      setMessage("Please enter a valid email address.");
    }
  };

  return (
    <div className="newsletter-container">
      <div className="newsletter-content">
        <h2>Stay up to date</h2>
        <p>Sign up for the newsletter now and benefit from it.</p>
        <form
          action="https://formsubmit.co/seacruisetravels.info@gmail.com"
          method="POST"
          onSubmit={handleSubmit}
          className="newsletter-form"
        >
          {/* Hidden fields for customization */}
          <input
            type="hidden"
            name="_subject"
            value="New Newsletter Subscription"
          />
          <input
            type="hidden"
            name="_autoresponse"
            value="Thank you for signing up for our newsletter! We’re excited to have you on board."
          />
          <input type="hidden" name="_template" value="table" />

          {/* Visible fields */}
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="newsletter-input"
          />
          <button type="submit" className="subscribe-button">
            Subscribe
          </button>
        </form>
        {message && <p className="subscription-message">{message}</p>}
      </div>
    </div>
  );
};

export default Newsletter;
